<template>
  <div v-if="users.length" class="question-box  mt-3">
    <transition name="fade">
      <QuestionBase
          :question="question"
          :errors="[]"
          :valid="false"
      >

          <div class="accordion sub-items">

            <ObjectCard
                v-for="(user, index) in usersSorted"
                v-model="users[index]"
                :key="index"
                type="user"
                :loading="loading"
                :title="user.email"
                :show-save="false"
                :disableClick="true"
                :disabled="!user.is_active"
                @delete="disableUserConfirm(user)"
            />

          </div>
      </QuestionBase>
    </transition>
  </div>
</template>

<script>
import {toast} from '@/mixins/toast'
import {http} from "@/services";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import ObjectCard from "@/components/common/questionnaires/question/questions/objects/ObjectCard";

export default {
  name: 'IntroducerUsers',
  mixins: [toast],
  components: {
    ObjectCard,
    QuestionBase
  },
  mounted() {
    if (this.$store.getters.user?.id) this.getUsers()
  },
  data() {
    return {
      users: [],
      question: {
        title: 'Active Users',
        subTitle: 'You can disable user access if needed'
      },
      db: {
        saveLocation: 'introducer', // required db for questionnaire
        saveField: 'introducer_users',
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
      show: this.$parent.$parent.$data.show,
      postErrors: {
      }
    }
  },
  computed: {
    usersSorted () {
      let users = this.users
      return users.sort((a, b) => a.id - b.id)
    }
  },
  methods: {
    deleteAccessRequestConfirm(access) {
      this.$bvModal
          .msgBoxConfirm(
              `Are you sure that want to revoke access for ${access.email}`,
              {
                title: 'Please Confirm',
                size: 'sm',
                buttonSize: 'sm',
                cancelVariant: 'outline-primary',
                okVariant: 'secondary',
                okTitle: 'Confirm',
                cancelTitle: 'Cancel',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
              }
          )
          .then((value) => {
            if (value) {
              this.deleteAccessRequest(access)
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    disableUserConfirm(user) {
      http.patch('introducer_users_access', {id: user.id}).then(
          () => {
            this.getUsers()
            this.toast(user.is_active ? 'User disabled' : 'User activated')
          }
      ).catch(
          error => {
            console.log(error)
          }
      )
    },
    getUsers() {
      http.get('introducer_users_access').then(
          response => {
            this.users = response.data
          }
      ).catch(
          error => {
            console.log(error)
          }
      )
    },
  }
}
</script>
