<template>
  <div class="main-content questionnaire">
    <div class="fieldset">
      <b-row>
        <b-col cols="6">
          <IntroducerAddAccessUser @refresh="getAccessRequests"/>
          <IntroducerAccessRequests ref="accessRequests" />
        </b-col>
      </b-row>

    </div>
  </div>

</template>

<script>
import IntroducerAccessRequests from "@/components/common/questionnaires/question/questions/introducer/IntroducerAccessRequests";
import IntroducerAddAccessUser from "@/components/common/questionnaires/question/questions/introducer/IntroducerAddAccessUser";

export default {
  name: 'AccessUsers',
  components: {IntroducerAddAccessUser, IntroducerAccessRequests},
  methods: {
    getAccessRequests() {
      this.$refs.accessRequests.getAccessRequests()
    }
  }
}
</script>
