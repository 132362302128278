<template>
  <b-row>
    <b-col cols="6">
      <h5>Personal Details</h5>
      <div class="main-content questionnaire mb-24">
        <div class="fieldset">
          <b-row>
            <b-col cols="10">
              <b-form-group
                  label="Your Name"
                  label-for="name"
                  class="mb-0"
              >
                <b-input-group class="mb-20">
                  <b-form-input
                      id="name"
                      v-model="form.name"
                      placeholder="Your name"
                      size="lg"
                      type="text"
                      :state="errors.name.length ? false : null"
                  />


                </b-input-group>
                <b-form-invalid-feedback
                    id="name-feedback"
                    :state="errors.name.length ? false : null"
                >
                  <p v-for="(error, index) in errors.name" :key="index+'errorName'"> {{ error }}</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="10">
              <b-form-group
                  label="Login Email"
                  label-for="email"
                  class="mb-0"
              >
                <b-input-group class="mb-20">
                  <b-form-input
                      id="email"
                      v-model="form.email"
                      placeholder="Your email address"
                      size="lg"
                      type="text"
                      :state="errors.email.length ? false : null"
                  />


                </b-input-group>
                <b-form-invalid-feedback
                    id="email-feedback"
                    :state="errors.email.length ? false : null"
                >
                  <p v-for="(error, index) in errors.email" :key="index+'errorEmail'"> {{ error }}</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="10">
              <b-form-group
                  label="Phone Number"
                  label-for="contact_phone"
                  class="mb-0"
              >
                <b-input-group class="mb-20">
                  <b-form-input
                      id="contact_phone"
                      v-model="form.contact_phone"
                      placeholder="Phone number"
                      size="lg"
                      type="text"
                      :state="errors.contact_phone.length ? false : null"
                  />


                </b-input-group>
                <b-form-invalid-feedback
                    id="contact_mobile-feedback"
                    :state="errors.contact_phone.length ? false : null"
                >
                  <p v-for="(error, index) in errors.contact_phone" :key="index+'errorContactPhone'"> {{ error }}</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>


          <b-row>
            <b-col cols="10">
              <b-form-group
                  label="Mobile Number"
                  label-for="contact_mobile"
                  class="mb-0"
              >
                <b-input-group class="mb-20">
                  <b-form-input
                      id="contact_mobile"
                      v-model="form.contact_mobile"
                      placeholder="Mobile phone number"
                      size="lg"
                      type="text"
                      :state="errors.contact_mobile.length ? false : null"
                  />

                </b-input-group>
                <b-form-invalid-feedback
                    id="contact_mobile-feedback"
                    :state="errors.contact_mobile.length ? false : null"
                >
                  <p v-for="(error, index) in errors.contact_mobile" :key="index+'errorContact_mobile'"> {{ error }}</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

        </div>

        <b-button @click="save">Update</b-button>

      </div>

      <h5>Avatar</h5>
      <div class="main-content questionnaire mb-24">
        <div class="fieldset">
          <b-row class="mb-30">
            <b-col cols="12">
              <div class="user-image-wrapper">
                <img v-if="!active"
                     :src="imgSrc"
                     alt=""/>

                <div v-if="active"
                     class="img-cropper">
                  <vue-cropper ref="cropper"
                               :aspect-ratio="1 / 1"
                               :maxCanvasHeight="1000"
                               :maxCanvasWidth="1000"
                               :src="imgSrc"
                               preview=".preview"/>
                </div>

                <input ref="input"
                       accept="image/*"
                       class="d-none"
                       name="image"
                       type="file"
                       @change="setImage"/>

                <b-btn-group class="d-flex flex-grow-1 pt-2">
                  <b-button v-if="active"
                            class="w-100"
                            variant="outline-primary ripple"
                            @click.prevent="reset">
                    Cancel
                  </b-button>
                  <b-button v-if="!active"
                            class="w-100"
                            variant="primary ripple"
                            @click.prevent="showFileChooser">{{
                      imgSrc ? 'Change' : 'Choose Image'
                    }}
                  </b-button>
                  <b-button v-if="active"
                            class="w-100"
                            variant="primary ripple"
                            @click.prevent="showFileChooser">Change
                  </b-button>
                  <b-button v-if="active"
                            class="w-100"
                            variant="primary ripple"
                            @click.prevent="saveImage">
                    Upload
                  </b-button>
                </b-btn-group>

              </div>
            </b-col>
          </b-row>
        </div>
      </div>


    </b-col>

    <b-col cols="6">

      <h5 class="mb-20">Training</h5>
      <ul v-if="user.is_introducer_training.length">
        <li v-for="(trainingCode, index) in user.is_introducer_training" :key="index">
          {{ user.introducer_training_skills.find(option => option[0] === trainingCode)[1] }}
        </li>
      </ul>
      <p v-else>You have no training skills</p>

      <h5 class="mb-20">Miscellaneous</h5>
      <ul>
        <li>Last logged in: {{ formatDateAndTimeAmPm(user.last_login) }}</li>
        <li>User Privileges: {{ user.is_introducer_admin ? 'Admin' : 'Basic' }}</li>
      </ul>

    </b-col>
  </b-row>
</template>

<script>
import {http} from "@/services";
import {dateFormat} from "@/mixins/dateFormat";
import {toast} from '@/mixins/toast'
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import axios from "axios";

export default {
  name: 'UserOverview',
  mixins: [dateFormat, toast],
  mounted() {
    this.setForm()
  },
  components: {
    VueCropper
  },
  computed: {
    user() {
      return this.$store.getters.user
    }
  },
  watch: {
    user: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value.image_thumb) {
          this.imgSrc = value.image_thumb;
        } else {
          this.imgSrc = require('@/assets/images/man-profile.svg');
        }
      }
    }
  },
  data() {
    return {
      form: {
        id: null,
        email: null,
        name: null,
        contact_phone: null,
        contact_mobile: null
      },
      errors: {
        email: [],
        name: [],
        contact_phone: [],
        contact_mobile: []
      },
      imgSrc: require('@/assets/images/man-profile.svg'),
      cropImg: '',
      cropImgBlob: '',
      active: false
    }
  },
  methods: {
    save() {
      this.clearErrors()
      http.patch('introducer_user_detail', this.form).then(
          () => {
            this.toast('User details updated')
          }
      ).catch(
          error => {
            this.toastError('Error saving user details')
            this.handleErrors(error.response.data)
          }
      )
    },
    handleErrors(errors) {
      let keys = Object.keys(errors);
      for (let i in keys) {
        this.errors[keys[i]] = errors[keys[i]];
      }
    },
    clearErrors() {
      let keys = Object.keys(this.errors)
      for (let i in keys) {
        this.errors[keys[i]] = []
      }
    },
    clearForm() {
      let keys = Object.keys(this.form)
      for (let i in keys) {
        this.form[keys[i]] = null
      }
    },
    setForm() {
      this.form.id = this.user.id
      this.form.name = this.user.name
      this.form.email = this.user.email
      this.form.contact_mobile = this.user.contact_mobile
      this.form.contact_phone = this.user.contact_mobile
    },
    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        this.active = true;
        reader.fileName = this.form.name + '.' + file.name.split('.').pop().toLowerCase();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    async uploadToAmazon(response) {
      let formData = new FormData();
      let keys = Object.keys(response.signature);
      for (let i in keys) {
        formData.append(keys[i],
            response.signature[keys[i]]);
      }
      formData.append('file',
          new Blob([this.cropImgBlob],
              {
                type: response.signature['Content-Type']
              }));

      return axios.post(response.postEndpoint,
          formData).then((res) => {
        console.log(res);
        return response.signature.key;
      }).catch((error) => {
        console.log(error);
        return false;
      });
    },
    reset() {
      this.form.image_thumb = null;
      this.$refs.cropper.reset();
      this.active = false;
      this.$nextTick(function () {
        this.$refs.input.value = '';
      });
    },
    async cropImage() {
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();

      this.imgSrc = this.cropImg;

      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
            this.cropImgBlob = blob;
          },
          'image/jpeg');

      this.active = false;
      this.$nextTick(function () {
        this.$refs.input.value = '';
      });
      return true
    },
    async getSignature() {
      let data = {
        filePath: `Introducer${this.user.is_introducer.introducer}${this.form.name}${this.user.id}.png`,
        user_image_file: true,
        contentType: this.imgSrc.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]
      };
      return http.post('upload_pre_sign',
          data).then((response) => {
        console.log(response.data)
        return response.data
      }).catch((error) => {
        this.toast('Error uploading image', 'Error', 'danger')
        console.log(error);
      });
    },
    async saveImage() {
      await this.cropImage()
      let signatureData = await this.getSignature()
      let image = await this.uploadToAmazon(signatureData);
      let data = {
        id: this.user.id,
        image_thumb: image
      }
      http.patch('introducer_user_detail', data).then(
          () => {
            this.toast('User image uploaded')
            this.$store.dispatch('userFetch', true)
          }
      ).catch(
          error => {
            console.log(error)
            this.toastError('Error uploading user image')
          }
      )

    }
  }
}
</script>
