<template>
  <div class="border-bottom-gray-200 pb-80 mt-20">
    <div class="container">
      <div class="section-title">
        <h4 class="p-3 mb-2">
          <span>Settings</span>
        </h4>
      </div>

      <div>
        <b-tabs v-if="user" content-class="mt-3">
          <b-tab title="Overview" active>
            <UserOverview />
          </b-tab>
          <b-tab title="Password">
            <ChangePasswordForm/>
          </b-tab>
          <b-tab v-if="user && user.is_introducer_admin" title="Grant User Access">
            <AccessUsers/>
          </b-tab>
          <b-tab v-if="user && user.is_introducer_admin" title="Users">
            <Users />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import ChangePasswordForm from "@/views/introducer/settings/ChangePasswordForm";
import AccessUsers from "@/views/introducer/settings/AccessUsers";
import UserOverview from "@/views/introducer/settings/UserOverview";
import Users from "@/views/introducer/settings/Users";

export default {
  name: 'Settings',
  components: {UserOverview, AccessUsers, ChangePasswordForm, Users},
  computed: {
    user() {
      return this.$store.getters.user
    }
  }
}
</script>
