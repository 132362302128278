<template>
  <div class="main-content questionnaire">
    <div class="fieldset">
      <b-row>
        <b-col cols="6">

          <IntroducerUsers />


        </b-col>
      </b-row>

    </div>
  </div>

</template>

<script>

import IntroducerUsers from "@/components/common/questionnaires/question/questions/introducer/IntroducerUsers";
export default {
  name: 'Users',
  components: {IntroducerUsers},
  methods: {

  }
}
</script>
